import instance from "@/plugin/axios";
import Swal from "sweetalert2";
import router from "@/router";

// Success Added
let successfulAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "success",
    title: message,
  });
};

// // Error Request
let errorAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "error",
    title: message,
  });
};
const userCoursesModule = {
  namespaced: true,
  state: {
    isLoading: true,
    isFirstLoad: true,
    userCourses: [],
    userTopCourses: [],
    userCoursesFav: [],
    userCoursesSub: [],
    userCourse: {},
    selectedLesson: {},
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getUserCourseIsLoading: (state) => state.isLoading,
    getIsFirstLoad: (state) => state.isFirstLoad,
    getUserCourses: (state) => state.userCourses,
    getUserTopCourses: (state) => state.userTopCourses,
    getUserCoursesFav: (state) => state.userCoursesFav,
    getUserCoursesSub: (state) => state.userCoursesSub,
    getUserCourse: (state) => state.userCourse,
    getSelectedLesson: (state) => state.selectedLesson,
  },
  mutations: {
    setSelectedLesson: (state, payload) => {
      state.selectedLesson = payload;
    },
    // Loading
    setLoading: (state, payload) => {
      state.isLoading = payload;
    },
    // First load
    setFirstLoad: (state, payload) => {
      state.isFirstLoad = payload;
    },
    //
    setUserCourses: (state, payload) => {
      state.userCourses = payload;
    },
    setUserTopCourses: (state, payload) => {
      state.userTopCourses = payload;
    },
    setUserSubCourses: (state, payload) => {
      state.userCoursesSub = payload;
    },

    setUserFavCourses: (state, payload) => {
      state.userCoursesFav = payload;
    },
    setUserCourse: (state, payload) => {
      state.userCourse = payload;
    },
    setIsFavCourse: (state, payload) => {
      let findIndex = state.userTopCourses.findIndex(
        (course) => course.id === payload.course_id
      );
      state.userTopCourses[findIndex].is_favorite =
        !state.userTopCourses[findIndex].is_favorite;
    },
    setIsFavCourses: (state, payload) => {
      let findIndex = state.userCourses.findIndex(
        (course) => course.id === payload.course_id
      );
      state.userCourses[findIndex].is_favorite =
        !state.userCourses[findIndex].is_favorite;
    },

    setUserCourseFromState: (state, payload) => {
      state.userCourse = state.userCourses;
      console.log(payload);
      // state.lessonsList[findIndex] = payload;
    },
    setIncreamentWatchedLesson: (state) => {
      state.userCourse.watched_lessons_count += 1;
    },
  },
  actions: {
    async userAllCourses({ commit }) {
      commit("setLoading", true);
      commit("setFirstLoad", true);
      instance
        .get("farmer/courses")
        .then((data) => {
          commit("setUserCourses", data.data.data);
          commit("setLoading", false);
          commit("setFirstLoad", false);
        })
        .catch((error) => console.log(error));
    },
    async userTopCourses({ commit }) {
      commit("setLoading", true);
      commit("setFirstLoad", true);
      instance
        .get("farmer/courses/top-8-courses")
        .then((data) => {
          commit("setUserTopCourses", data.data.data);
          commit("setLoading", false);
          commit("setFirstLoad", false);
        })
        .catch((error) => console.log(error));
    },
    async allSubCourses({ commit }) {
      commit("setLoading", true);
      commit("setFirstLoad", true);
      instance
        .get("farmer/courses/subscribed-courses")
        .then((data) => {
          commit("setUserSubCourses", data.data.data);
          commit("setLoading", false);
          commit("setFirstLoad", false);
        })
        .catch((error) => console.log(error));
    },
    async allFavCourses({ commit }) {
      commit("setLoading", true);
      commit("setFirstLoad", true);
      instance
        .get("farmer/courses/favorits")
        .then((data) => {
          commit("setUserFavCourses", data.data.data);
          commit("setLoading", false);
          commit("setFirstLoad", false);
        })
        .catch((error) => console.log(error));
    },
    async userCourse({ commit }, payload) {
      commit("setLoading", true);
      instance
        .get(`farmer/courses/${payload}`)
        .then((data) => {
          commit("setUserCourse", data.data.data);
          commit("setLoading", false);
        })
        .catch((error) => console.log(error));
    },
    async updateUserCourse({ commit }, payload) {
      instance
        .get(`farmer/courses/${payload}`)
        .then((data) => {
          commit("setUserCourse", data.data.data);
        })
        .catch((error) => console.log(error));
    },

    userCourseFromState({ commit }, payload) {
      commit("setUserCourseFromState", payload);
    },

    async selectedLessonDetails({ commit }, payload) {
      instance
        .get(`farmer/courses/${payload.course_id}/lessons/${payload.lesson_id}`)
        .then((data) => {
          commit("setSelectedLesson", data.data.data);
        })
        .catch((error) => errorAdded(error));
    },

    async userSubCourse({ commit }, payload) {
      instance
        .post("farmer/courses/subscribe", payload)
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setFirstLoad", false);
          router.push({
            name: "HomeCourseView",
            params: { id: payload.course_id },
          });
        })
        .catch((error) => errorAdded(error));
    },

    async logOutFromCourse({ commit }, payload) {
      return instance
        .post("farmer/courses/out-of-course", payload)
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setLoading", false);
          commit("setFirstLoad", false);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },

    async userAddFav({ commit }, payload) {
      instance
        .post("farmer/courses/add-to-favorite", payload)
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setIsFavCourse", payload);
          // commit("setUserCourses", data.data.data);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
    async userCoursesAddFav({ commit }, payload) {
      instance
        .post("farmer/courses/add-to-favorite", payload)
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setIsFavCourses", payload);
          commit("setAddCoursesToFav", payload);

          // commit("setUserCourses", data.data.data);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },

    async userTakeLesson({ commit }, payload) {
      instance
        .post("farmer/courses/take-lesson", payload)
        .then((data) => {
          if (data.status == 200) {
            commit("setIncreamentWatchedLesson");
          }
        })
        .catch((error) => console.log(error));
    },
  },
};

export default userCoursesModule;
